import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/cryptowander/Maker/test/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Governing Better Money`}</h1>
    <p>{`We are committed to decentralization. This site is one more step along the steady path which began with the first MKR vote on our `}<a parentName="p" {...{
        "href": "https://blog.makerdao.com/foundation-proposal-v2/"
      }}>{`Core Foundation Principles`}</a>{`, gathered momentum with the `}<a parentName="p" {...{
        "href": "https://blog.makerdao.com/the-transfer-of-mkr-token-control-to-governance-the-final-step/"
      }}>{`transfer of MKR token control from the Foundation to the governance community`}</a>{` and continues now with Maker Improvement Proposals. `}</p>
    <p>{`The purpose of this site is to provide the MakerDAO governance community with the tools and information required to manage and improve the DAI stablecoin system effectively. `}</p>
    <p>{`You can monitor updates to the protocol on this site, or dive in and join the daily debates on our forum:`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      